import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { MessageGatewayType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class MessageFormatService {
  constructor(private http: HttpClient) {}

  formatText(data: {
    text: string;
    gateway: MessageGatewayType;
  }): Observable<string> {
    const { gateway, text } = data;

    return this.http
      .post(`general/text_format/sms/${gateway}`, { text })
      .pipe(
        map(
          ({ data }: IResponseSuccess<{ text_formatted: string }[]>) =>
            data[0].text_formatted,
        ),
      );
  }
}
